var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _vm.isPrimaryState
            ? _c("a-tag", { attrs: { color: "orange" } }, [
                _vm._v(" " + _vm._s(_vm.$t(_vm.params.data.state)) + " ")
              ])
            : _vm._e()
        ],
        1
      ),
      _c("a-col", { attrs: { span: 24 } }, [
        _c("p", { staticClass: "mb-none text-ellipsis" }, [
          _vm._v(_vm._s(_vm.name))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }