var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "type text-ellipsis" },
    [
      _c("a-tag", { attrs: { color: _vm.colorMapping } }, [
        _vm._v(" " + _vm._s(_vm.$t(_vm.template)) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }