var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "page-header",
        {
          staticClass: "mb-xs",
          attrs: { "show-back-icon": "", title: _vm.currentProject.name },
          on: { back: _vm.handleBackToProjectList }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "inbox",
                    disabled: !_vm.isRestoreEnable
                  },
                  on: {
                    click: function($event) {
                      _vm.confirmRestoreModal = true
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Restore")) + " ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("search-box", {
        staticClass: "pb-sm",
        attrs: { queryParam: _vm.queryParam },
        on: {
          changeTitle: _vm.changeTitle,
          changeSlug: _vm.changeSlug,
          handleSearch: _vm.handleSearch,
          handleReset: _vm.handleReset
        }
      }),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            _c("SGrid", {
              ref: "sGrid",
              attrs: {
                "grid-name": "page-archive",
                rowData: _vm.pages,
                enableCheckbox: true,
                columnDefs: _vm.columnDefs,
                gridContext: _vm.context,
                pagination: _vm.pagination,
                rowHeight: 124
              },
              on: {
                onPageChange: _vm.handleChangePage,
                selectionChanged: _vm.handleSelectPage
              }
            })
          ],
          1
        )
      ]),
      _vm.confirmRestoreModal
        ? _c("ArchiveModal", {
            attrs: {
              visible: _vm.confirmRestoreModal,
              "row-data": _vm.restorePages,
              title: _vm.$t("Do you want to restore the selected page?")
            },
            on: {
              cancel: function() {
                _vm.confirmRestoreModal = false
              },
              ok: _vm.onClickRestore
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }