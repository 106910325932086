//
//
//
//
//
//
//
//
//
//
//
//
//

import { PAGE_STATE } from '@/enum';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    name() {
      return this.params.data.name;
    },
    isPrimaryState() {
      return this.params?.data?.state === PAGE_STATE.PRIMARY;
    }
  }
});
