import NameCellRenderer from './NameCellRenderer.vue';
import CreatedCellRenderer from './CreatedCellRenderer.vue';
import DesCellRenderer from './DesCellRenderer.vue';
import TemplateCellRenderer from './TemplateCellRenderer.vue';
import PathCellRenderer from './PathCellRenderer.vue';
import PhotoCellRenderer from './PhotoCellRenderer.vue';
import ActionsCellRenderer from './ActionsCellRenderer.vue';
export {
  NameCellRenderer,
  CreatedCellRenderer,
  DesCellRenderer,
  TemplateCellRenderer,
  PathCellRenderer,
  PhotoCellRenderer,
  ActionsCellRenderer,
};
