//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  computed: {
    data() {
      return this.params.data || {};
    },
    template() {
      return this.data?.template || 'CATALOG';
    },
    colorMapping() {
      const mapping = {
        CATALOG: 'blue',
        PORTAL: 'purple'
      };
      return mapping[this.template];
    }
  }
});
